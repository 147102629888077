import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Wrapper, Item } from "./styles";
import { GiPill } from "react-icons/gi";
import { MdHelp, MdSettings } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";

import colors from "../../shared/colors";
import { ConfigContext } from "../../shared/contexts/ConfigContext";

function TabBottom() {
  let history = useHistory();
  const [currentMenu, setCurrent] = React.useState(1);
  const { footerVisible } = useContext(ConfigContext);

  function changeMenu(menu) {
    navigationTabBar(menu);
    setCurrent(menu);
  }

  function navigationTabBar(currentMenu) {
    switch (currentMenu) {
      case 1:
        history.push("/home");
        break;
      case 2:
        history.push("/questions");
        break;
      case 3:
        history.push("/rank");
        break;
      case 4:
        history.push("/settings");
        break;
      default:
        console.log("Erro");
    }
  }

  if (!footerVisible) return null;

  return (
    <Wrapper>
      <Item onClick={() => changeMenu(1)}>
        <GiPill
          color={currentMenu === 1 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => changeMenu(2)}>
        <MdHelp
          color={currentMenu === 2 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => changeMenu(3)}>
        <FaTrophy
          color={currentMenu === 3 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => changeMenu(4)}>
        <MdSettings
          color={currentMenu === 4 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
    </Wrapper>
  );
}

export default TabBottom;
