import React, { useState, createContext } from "react";

export const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [headerVisible, setHeaderVisible] = useState(true);
  const [footerVisible, setFooterVisible] = useState(true);
  const [loadingVisible, setLoading] = useState(false);

  const changeHeaderVisibity = (visible) => {
    setHeaderVisible(visible);
  };

  const changeFooterVisibility = (visible) => {
    setFooterVisible(visible);
  };

  const changeLoading = (visible) => {
    setLoading(visible);
  };

  return (
    <ConfigContext.Provider
      value={{
        headerVisible,
        changeHeaderVisibity,
        footerVisible,
        changeFooterVisibility,
        loadingVisible,
        changeLoading,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
