import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import AuthProvider from "./shared/contexts/AuthContext";
import ConfigProvider from "./shared/contexts/ConfigContext";
import PrivateRoute from "./PrivateRoute";

import Layout from "./components/Layout";

const Login = withRouter(lazy(() => import("./pages/auth/Login/Login")));
const ForgotPassword = withRouter(
  lazy(() => import("./pages/auth/ForgotPassword/ForgotPassword"))
);
const Pills = withRouter(lazy(() => import("./pages/app/Pills/Pills")));
const Details = withRouter(lazy(() => import("./pages/app/Details/Details")));
const Questions = withRouter(
  lazy(() => import("./pages/app/Questions/Questions"))
);
const Rank = withRouter(lazy(() => import("./pages/app/Rank/Rank")));
const Settings = withRouter(
  lazy(() => import("./pages/app/Settings/Settings"))
);

const QuestionPill = withRouter(
  lazy(() => import("./pages/app/QuestionPill/QuestionPill"))
);

const QuestionAnswer = withRouter(
  lazy(() => import("./pages/app/QuestionPill/QuestionAnswer"))
);

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <AuthProvider>
          <ConfigProvider>
            <Layout>
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/forgotpass" exact component={Login} />
                <Route
                  path="/rememberPassword"
                  exact
                  component={ForgotPassword}
                />
                <PrivateRoute>
                  <Route path="/home" exact component={Pills} />
                  <Route path="/details/:id" exact component={Details} />
                  <Route path="/questions" exact component={Questions} />
                  <Route
                    path="/question/:agenda/:id"
                    exact
                    component={QuestionPill}
                  />
                  <Route path="/answer/:id" exact component={QuestionAnswer} />
                  <Route path="/rank" exact component={Rank} />
                  <Route path="/settings" exact component={Settings} />
                </PrivateRoute>
              </Switch>
            </Layout>
          </ConfigProvider>
        </AuthProvider>
      </Suspense>
    </Router>
  );
};
export default Routes;
